import { createStandaloneToast } from '@chakra-ui/react';
import { captureException, withScope } from '@sentry/nextjs';
import axios, { AxiosError, AxiosInstance, isAxiosError } from 'axios';
import { signOut } from 'next-auth/react';

import { getSuccessToastConfigs } from '@/lib/toastConfigs';

const { toast } = createStandaloneToast();

/**
 * Function for getting the URL from axios error
 */
const getURLFromAxiosError = (error: AxiosError) => {
	const axiosConfigURL = `${error.config?.baseURL}${error.config?.url}`;

	let url = 'No URL';

	if (error?.request?.responseURL) {
		url = error?.request?.responseURL;
	} else if (axiosConfigURL) {
		url = axiosConfigURL;
	}

	return url;
};

/**
 * Function for abstracting axios attaching the authorization on interceptors
 * got from next-auth getSession
 *
 * @author Indra Lukmana
 * @date 9/13/2022 - 1:55:42 PM
 */
const ApiClient = ({ baseURL }: { baseURL: string }): AxiosInstance => {
	const instance = axios.create({ baseURL });
	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			//catch unauthorized error, end session, and redirect to login page
			if (401 === error?.response?.status) {
				if (toast.isActive('logout-toast') === false) {
					toast(
						getSuccessToastConfigs({
							status: 'warning',
							title: 'Sesi Berakhir',
							description: 'Sesi anda telah berakhir, silakan login kembali',
							id: 'logout-toast',
							isClosable: true,
							onCloseComplete: async () => {
								// Signout from next-auth (clear the session)
								// the redirect to login page is handled in AuthWrapper
								await signOut({ callbackUrl: '/login', redirect: false });
							},
						})
					);
				}
				throw error;
			}

			// skipped patterns
			const pattern = /login|lms\/akun/;

			const statusCode = error.response?.status ?? 0;

			// skip POST on `pattern` for 4xx status family
			if (
				isAxiosError(error) &&
				statusCode >= 400 &&
				statusCode < 500 &&
				error.config?.method === 'post' &&
				pattern.test(error?.config?.url ?? '')
			) {
				throw error;
			}

			// skip GET on `pattern` and 4xx status family
			if (
				isAxiosError(error) &&
				statusCode >= 400 &&
				statusCode < 500 &&
				error.config?.method === 'get' &&
				pattern.test(error?.config?.url ?? '')
			) {
				throw error;
			}

			if (isAxiosError(error) && error.response?.status !== 401) {
				withScope((scope) => {
					const method = error?.config?.method?.toUpperCase() ?? 'No Method';

					const url = getURLFromAxiosError(error);

					scope.setTransactionName(`${method}: ${url}`);
					scope.addBreadcrumb({
						category: 'Axios Error',
						level: 'error',
						data: {
							rest: {
								method,
								url,
								params: error.config?.params,
							},
							data: {
								responseData: error.response?.data,
								payloadData: error.config?.data,
							},
							token: error.config?.headers.Authorization,
						},
						type: 'error',
					});
					scope.setFingerprint([
						'Axios Error',
						method,
						url,
						error.stack ?? 'No Stack',
					]);

					captureException(error);
				});

				throw error;
			}

			console.error(error);
			captureException(error);

			throw error;
		}
	);

	return instance;
};

const setClientToken = (instance: AxiosInstance, token: string) => {
	instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const apiClientEDM = ApiClient({
	baseURL: 'https://edm-node.erkam-v2.kemenag.go.id/api',
});

export { apiClientEDM, setClientToken };
